import React, { useEffect, useState, Fragment, useLayoutEffect } from 'react';
// import { Button, ButtonGroup, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Form, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useParams, useLocation, useNavigate, useRef } from 'react-router-dom';
import videojs from 'video.js';
import Draggable from 'react-draggable';
import HttpRequest from './../../Common/httpServices/http.service';
import "./playerRender.css";
import "../../style.css";
import "../../mediaQuery.css";
import useWindowDimensions from "./../../Common/viewport-dimentions/viewport";
import helper from '../../Common/helper/helper';
import Alert from 'react-bootstrap/Alert';
import { v4 as uuid } from 'uuid';
// 'clk' - click tracker, when islanding page is 1
// 'csc' - when player is ready
// 'resp' - after src is added to player

const PlayerRender = () => {
    /* states */
    const [playerVideoInfo, setPlayerVideoInfo] = useState("");
    const [player, setPlayer] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [pauseSecMode, setPauseSecMode] = useState(false);
    const [clickActionEnable, setClickActionEnable] = useState(true);
    const [action, setAction] = useState({});
    let [activeDrags, setActiveDrags] = useState(0);
    let [deltaPosition, setDeltaPosition] = useState({
        x: 0,
        y: 0
    });
    const [showBranching, setShowBranching] = useState(false);
    const [poster, setPoster] = useState("");
    const [uploadType, setUploadType] = useState("");
    const [branchingMappingShowHide, setBranchingMappingShowHide] = useState(true);
    const [returnBtnVisible, setReturnBtnVisible] = useState();
    const [interactionData, setInteractionData] = useState({});
    // const [quizFieldIndex, setQuizFieldIndex] = useState(0);
    const [quizIndexObj, setQuizIndexObj] = useState({});
    const [randomStr, setRandomStr] = useState(new Date().getTime());
    const [randomCurrentStr, setCurrentRandomStr] = useState(new Date().getTime());
    const [showMsgEnable, setShowMsgEnable] = useState(false);
    const [pauseSecModeTimerId, setPauseSecModeTimerId] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);
    const [templateVisible, setTemplateVisible] = useState(false);
    const [templateContent, setTemplateContent] = useState("");
    const [branchTemplateId, setBranchTemplateId] = useState("");
    const [fixedTemplateVisible, setFixedTemplateVisible] = useState(false);
    const [fixedTemplateContent, setFixedTemplateContent] = useState("");
    const [fixedTemplateId, setFixedTemplateId] = useState("");
    const [videoIdsArr, setVideoIdsArr] = useState([]);
    const [clkTrackerFired, setClkTrackerFired] = useState(false);
    const [landingPageObj, setLandingPageObj] = useState(false);
    const [radioValueArr, setRadioValueArr] = useState({});
    const [playerURLHistory, setPlayerURLHistory] = useState({});
    const [quizFormScore, setquizFormScore] = useState('');
    const [landingPageObjVisible, setLandingPageObjVisible] = useState('');
    const { height, width } = useWindowDimensions();

    const location = useLocation();
    let { videGuid } = useParams();
    const param = new URLSearchParams(document.location.search);
    const video_id = param.get('v');
    const videoHeight = param.get('h');
    const videoWidth = param.get('w');
    const siteId = param.get('siteId');
    const cbuster = param.get('cbuster');
    const gclckunesc = param.get('gclckunesc');
    const tagStatus = param.get('tag-status');
    const newModalWidth = (Math.round((Number(videoWidth) * 80) / 100));
    const newModalHeight = (Math.round((Number(videoHeight) * 80) / 100));
    const calculatedIconWidth = (Math.round((Number(videoWidth) * 4) / 100));
    const calculatedBranchingWidth = (Math.round((Number(videoWidth) * 16) / 100));
    const calculatedBranchingHeight = (Math.round((Number(videoWidth) * 15) / 100));
    const calculatedBranchingImgsHeight = (Math.round((Number(videoWidth) * 10) / 100));
    const calculatedPlayerBranchingWidth = (Math.round((Number(videoWidth) * 60) / 100));
    // const calculatedIconHeight = (Math.round((Number(videoHeight) * 8)/100));
    // const formFontSize = (Math.round((Number(videoWidth) * 4)/100));
    const playerWidth = 640;
    const playerHeight = 360;

    // const widthDiffPercent = Math.round(((playerWidth - videoWidth)*100)/playerWidth);
    // const heightDiffPercent = Math.round(((playerHeight - videoHeight)*100)/playerHeight);

    // const randomStr = new Date().getTime();
    let skipInteraction = false;
    let uuidVal = '';

    let calcVideoWidthPercent = Math.round(((Number(videoWidth) - playerWidth) / playerWidth) * 100);
    if (calcVideoWidthPercent < 0) {
        calcVideoWidthPercent = 100 - (Math.abs(calcVideoWidthPercent));
    } else {
        calcVideoWidthPercent = 100 + (Math.abs(calcVideoWidthPercent));
    }

    let calcVideoHeightPercent = Math.round(((Number(videoHeight) - playerHeight) / playerHeight) * 100);
    if (calcVideoHeightPercent < 0) {
        calcVideoHeightPercent = 100 - (Math.abs(calcVideoHeightPercent));
    } else {
        calcVideoHeightPercent = 100 + (Math.abs(calcVideoHeightPercent));
    }

    // draggable 
    let onStart = () => {
        setActiveDrags(++activeDrags);
    };
    let onStop = () => {
        setActiveDrags(--activeDrags);
    };
    const dragHandlers = { onStart: onStart, onStop: onStop };

    const handleDrag = (e, ui) => {
        const contentId = ui.node.id;
        // setDeltaPosition to states
        setDeltaPosition({
            x: ui.lastX,
            y: ui.lastY
        })
        // 
        const draggableChildDiv = document.getElementById(contentId);
        const floatingDivImg = document.getElementById(`floating_${contentId}`);

        // get and set overfloat div hight and width it's parent div
        if (uploadType === "image") {
            floatingDivImg.style.width = (draggableChildDiv.offsetWidth - 15) + "px";
            floatingDivImg.style.height = (draggableChildDiv.offsetHeight - 15) + "px";
        }
        return true;
    };

    function removeSeekbarIcons() {
        try {
            let seekbarIconImage = document.getElementsByClassName("my-image");
            let seekbarIconHotspot = document.getElementsByClassName("my-hotspot");
            let seekbarIconText = document.getElementsByClassName("my-text");
            let seekbarIconForm = document.getElementsByClassName("my-form");

            const length = [...seekbarIconImage, ...seekbarIconHotspot, ...seekbarIconText, ...seekbarIconForm].length;
            if (length > 0) {
                for (let i = 0; i < length; i++) {
                    if (seekbarIconImage.length > 0) {
                        seekbarIconImage[0].remove();
                    }
                    if (seekbarIconHotspot.length > 0) {
                        seekbarIconHotspot[0].remove();
                    }
                    if (seekbarIconText.length > 0) {
                        seekbarIconText[0].remove();
                    }
                    if (seekbarIconForm.length > 0) {
                        seekbarIconForm[0].remove();
                    }
                }
            }
        } catch (e) { console.log(e); }
        return true;
    }

    const manageTemplateDivVisible = (flag) => {
        // debugger;
        let element = document.getElementById('template_div_id');
        if (element) {
            if (flag) {
                element.classList.add('hideAutoFixedTemp');

            }
            else {
                element.classList.remove('hideAutoFixedTemp');
                document.getElementById('BranchingTemplateCont').style.height = 'auto'; // template height
                document.getElementById("template_div_id").style.height = 'auto'; // fixed template height

            }
        }
    }

    const navigateToBranch = async (object) => {
        try {
            // ;
            if (Number(object.mappingType) > -1 && Number(object.mappingType) < 2) {
                if (Number(object.mappingType) === 0) { // video mapping
                    setReturnBtnVisible(false);
                    setTimeout(() => {
                        setReturnBtnVisible(true);
                    }, (Number(object.replayVideoTime) * 1000));
                    if (playerURLHistory.prevVideoId) {
                        playerURLHistory.prevVideoId += ',' + playerURLHistory.currentVideoId;
                    } else {
                        playerURLHistory.prevVideoId = playerURLHistory.currentVideoId;
                    }
                    playerURLHistory.currentVideoId = object.mappingId;
                    setPlayerURLHistory(playerURLHistory);

                    localStorage.setItem('currentVideoId' + '-' + video_id + '__' + siteId + '__' + randomStr, object.mappingId);
                    getVideoById(player, object.mappingId);
                } else if (Number(object.mappingType) === 1) { // template mapping
                    // debugger;
                    player.pause();
                    // setFixedTemplateVisible(false); // disable fixed template
                    manageTemplateDivVisible(true);
                    document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                    // let element = document.getElementById('template_div_id');
                    // if (element) {
                    //     element.classList.add('hideAutoFixedTemp');
                    // }
                    setTemplateVisible(true); //  enable branching template
                    // get template data
                    let templateId = object.mappingId;
                    const templateData = playerVideoInfo.templateVideoList.filter((data) => {
                        if (data.id === templateId) {
                            return true;
                        }
                    })
                    console.log(templateData);
                    // const templateDataById = playerVideoInfo.templateVideoList[0]; //await getTemplateById(object.mappingId);
                    const templateDataById = templateData[0];
                    setTemplateContent(templateDataById.templateContent);
                    const scriptTag = await makeJs(templateDataById);
                    document.head.appendChild(scriptTag);
                    setBranchTemplateId(templateId);



                    // setReturnBtnVisible(false);
                    // setTimeout(() => {
                    //     setReturnBtnVisible(true);
                    // }, (Number(object.replayVideoTime) * 1000));
                }

                // setReturnBtnVisible(false);
                // // remove previous video seekbar markers
                // // removeSeekbarIcons();

                // setTimeout(() => {
                //     setReturnBtnVisible(true);
                // }, (Number(object.replayVideoTime) * 1000));


            }

        } catch (e) { console.log(""); }
        return true;
    }

    const getVideoById = async (player, mainvideoId) => {
        try {
            // let mainvideoId = String(video_id);
            if (siteId) {
                let adServingRes = '';
                let adServingJsonResponse = '';
                const fetchInteractionData = Object.keys(interactionData).length ? false : true;
                if (fetchInteractionData && tagStatus !== 'DRAFT') {
                    // 
                    // var arrFrames = window.parent.document.getElementsByTagName("iframe");
                    // for (var i = 0; i < arrFrames.length; i++) {
                    //     ;
                    //     if (arrFrames[i].contentWindow === window) alert("yay!");
                    // }
                    // window.parent.document.getElementById("adcontainer-7e9b003c-9b11-11ed-bf1d-a5a5c37651adq").height = 10;
                    try {

                        let secure = window.location.protocol === 'https:' ? 1 : 0;

                        if (tagStatus === 'TEST') {
                            adServingRes = await fetch("//adt.truereach.co.in/impag/ads?site-id=" + siteId + "&kdua=1&kdip=1&fmt=auto&ver=s2s_1&w=&h=&cbuster=" + cbuster + "&ivrf=1&uid=" + uuidVal + "&sec=" + secure);
                        } else {
                            adServingRes = await fetch("//ads-sg.momagic.com/impag?site-id=" + siteId + "&kdua=1&kdip=1&fmt=auto&ver=s2s_1&w=&h=&cbuster=" + cbuster + "&ivrf=1&uid=" + uuidVal + "&sec=" + secure);
                        }
                        // https://ads.momagic.com/impag?site-id=01023642-aca4-b601-6ce1-bb4cea00002c&kdua=1&kdip=1&fmt=auto&ver=s2s_1&w=&h=&cbuster=null&ivrf=1
                        // adServingRes = await fetch("http://adt.truereach.co.in/impag?site-id=010a8124-dde5-9401-7085-300daa000001&site_guid=010a8124-dde5-9401-7085-300daa000001&ua=Mozilla%2F5.0+(SymbianOS%2F9.4%3B+Series60%2F5.0+Nokia5230%2F21.0.004%3B+Profile%2FMIDP-2.1+Configuration%2FCLDC-1.1+)+AppleWebKit%2F525+(KHTML%2C+like+Gecko)+Version%2F3.0+BrowserNG%2F7.2.5.2+3gpp-gba&ip=49.36.177.214&w=300&h=250&fmt=auto&ver=s2s_1&sec=0&ivrf=1");
                        adServingJsonResponse = await adServingRes.json();
                        if (Object.keys(adServingJsonResponse).length !== 0) {
                            console.log(adServingJsonResponse);
                        } else {
                            console.log('empty');
                            document.getElementById('videoDiv').style.display = 'none';
                            document.getElementById('adcontainer-' + siteId).style.height = '0px';
                            document.getElementById('adcontainer-' + siteId).style.width = '0px';
                            return false;
                        }
                    } catch (e) {
                        console.log('catch', e);
                        document.getElementById('videoDiv').style.display = 'none';
                        document.getElementById('adcontainer-' + siteId).style.height = '0px';
                        document.getElementById('adcontainer-' + siteId).style.width = '0px';
                        return false;
                    }
                }
                // const response = await HttpRequest.GET(encodeURI("http://adt.truereach.co.in/adserving/impag/ads?site-id=7e9b003c-9b11-11ed-bf1d-a5a5c37651ad&kdua=1&kdip=1&fmt=auto&ver=s2s_1&w=&h=&&ivrf=1"), {});

                // working
                // const res = fetch('https://ads-sg.momagic.com/adserving/impag/ads?site-id=0142010a-8400-1b01-7fda-4bac60000013&ua=Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36&ip=103.161.56.198&fmt=auto&ver=s2s_1&w=580&h=400gdpr=0&gaid=9aa11355-68b2-5566-b84a-18456a1y000&ivrf=1', {
                //     credentials: 'include'
                // });
                // console.log('ivrfResponseData ', jsonResponse.ivrfResponseData);

                // const fetchInteractionData = Object.keys(interactionData).length ? false : true;
                const response = await HttpRequest.GET_BY_ID_INI("/public/publicApi", mainvideoId, false);
                if (response.data.status === 'SUCCESS') {
                    let videoInfo = response.data.object.publicApi;
                    if (fetchInteractionData) {
                        if (adServingJsonResponse) {
                            videoInfo.interactionEventData = adServingJsonResponse.ivrfResponseData;
                        }
                    }
                    let images = videoInfo.imageList || [];
                    let hotspots = videoInfo.hotspotSettingList || [];
                    let text = videoInfo.textSettingList || [];
                    let formSettingsList = videoInfo.formSettingsList || [];
                    let quizIdIndexObj = {};
                    formSettingsList.map((formSetting, formSettingIndex) => {
                        if (formSetting.formType === 2) {
                            return quizIdIndexObj[formSetting.id] = 0;
                        }
                    });
                    setQuizIndexObj(quizIdIndexObj);
                    let branchMappingList = videoInfo.branchMappingList || [];
                    let templateVideoList = videoInfo.templateVideoList || [];
                    let sortedBranching = branchMappingList.sort((objectA, objectB) => parseFloat(objectA.mappingOrder) - parseFloat(objectB.mappingOrder));

                    let contentMedia = [...images, ...hotspots, ...text, ...formSettingsList];
                    contentMedia = contentMedia.map((renderContent, imageIndex) => {
                        let draggablePropsObj = JSON.parse(renderContent.draggableProps);
                        if (draggablePropsObj.defaultPosition.x > 0) {

                            // let calcPer = ((draggablePropsObj.defaultPosition.x / playerWidth) * 100);
                            // let newVideoWidth = ((videoWidth * calcPer) / 100);
                            // if (renderContent.mediaType == 'image') {
                            //     if (renderContent.imageAreaWidth) {
                            //         let newImageWidth = (Math.round((Number(renderContent.imageAreaWidth.replace('px', '')) * calcVideoWidthPercent) / 100) - 0) + 'px';
                            //         renderContent.imageAreaWidth = newImageWidth;
                            //     }
                            // } else if (renderContent.mediaType == 'TXT') {
                            //     calcVideoWidthPercent = calcVideoWidthPercent;
                            //     let txtWidth = draggablePropsObj.width;
                            //     let newTxtWidth = (Math.round((Number(txtWidth) * calcVideoWidthPercent) / 100) - 0);
                            //     draggablePropsObj.width = newTxtWidth;
                            // } else if (renderContent.mediaType == 'Form') {
                            //     let newTxtWidth = (Math.round((Number(videoWidth) * 80) / 100));
                            //     draggablePropsObj.width = newTxtWidth;
                            // }
                            // draggablePropsObj.defaultPosition.x = newVideoWidth;
                        }
                        if (draggablePropsObj.defaultPosition.y > 0) {
                            // let calcPer = ((draggablePropsObj.defaultPosition.y / playerHeight) * 100);
                            // let newVideoHeight = ((videoHeight * calcPer) / 100);

                            // if (renderContent.mediaType == 'image') {
                            //     if (renderContent.imageAreaWidth) {
                            //         let newImageHeight = (Math.round((Number(renderContent.imageAreaWidth.replace('px', '')) * calcVideoHeightPercent) / 100) - 0) + 'px';
                            //         renderContent.imageAreaWidth = newImageHeight;
                            //     }

                            // } else if (renderContent.mediaType == 'TXT') {
                            //     calcVideoHeightPercent = calcVideoHeightPercent;
                            //     let txtHeight = draggablePropsObj.height;
                            //     let newTxtHeight = (Math.round((Number(txtHeight) * calcVideoHeightPercent) / 100) - 0);
                            //     draggablePropsObj.height = newTxtHeight;
                            // } else if (renderContent.mediaType == 'Form') {
                            //     let newTxtHeight = (Math.round((Number(videoHeight) * 70) / 100));
                            //     draggablePropsObj.height = newTxtHeight;
                            // }
                            // draggablePropsObj.defaultPosition.y = newVideoHeight;
                        }
                        draggablePropsObj = JSON.stringify(draggablePropsObj);
                        renderContent.draggableProps = draggablePropsObj;
                        return renderContent;
                    });

                    let sortedContentMedia = contentMedia.sort((objectA, objectB) => parseFloat(objectA.startTime) - parseFloat(objectB.startTime));

                    videoInfo.contentMedia = sortedContentMedia;
                    videoInfo.branchMappingList = videoInfo.id == video_id ? sortedBranching : [];
                    let randomCurrentStr = new Date().getTime();
                    setCurrentRandomStr(randomCurrentStr);

                    window.localStorage.setItem('randomCurrentStr' + video_id, randomCurrentStr);

                    setPoster(videoInfo.posterImagePath);

                    playerURLHistory.mainVideoId = String(playerVideoInfo.id);
                    playerURLHistory.currentVideoId = String(playerVideoInfo.id);
                    playerURLHistory.prevVideoId = '';
                    setPlayerURLHistory(playerURLHistory);
                    document.getElementsByClassName('draggable_parent_div')[0].classList.remove('parentOverlay');
                    setPlayerVideoInfo(videoInfo); // set fetch id details into state object
                    // load fixed template
                    if (templateVideoList.length > 0) {
                        showTemplateHeader(videoInfo);
                    }

                    if (!landingPageObj) {
                        if (videoInfo.landingPage) {
                            let draggablePropsObj = JSON.parse(videoInfo.draggableProps);
                            if (draggablePropsObj) {
                                // if (draggablePropsObj.defaultPosition.x > 0) {
                                //     let calcPer = ((draggablePropsObj.defaultPosition.x / playerWidth) * 100);
                                //     let newVideoWidth = ((videoWidth * calcPer) / 100);
                                //     draggablePropsObj.defaultPosition.x = newVideoWidth;
                                // }
                                // if (draggablePropsObj.defaultPosition.y > 0) {
                                //     let calcPer = ((draggablePropsObj.defaultPosition.y / playerHeight) * 100);
                                //     let newVideoHeight = ((videoHeight * calcPer) / 100);
                                //     draggablePropsObj.defaultPosition.y = newVideoHeight;
                                // }
                                // if (draggablePropsObj.width) {
                                //     let txtWidth = draggablePropsObj.width;
                                //     let newTxtWidth = (Math.round((Number(txtWidth) * calcVideoWidthPercent) / 100) - 0);
                                //     draggablePropsObj.width = newTxtWidth;
                                // }
                                // if (draggablePropsObj.height) {
                                //     let txtHeight = draggablePropsObj.height;
                                //     let newTxtHeight = (Math.round((Number(txtHeight) * calcVideoHeightPercent) / 100) - 0);
                                //     draggablePropsObj.height = newTxtHeight;
                                // }
                                videoInfo.draggableProps = JSON.stringify(draggablePropsObj);

                                let ctaDisplayContent = videoInfo.ctaContent || "<p><button style=\"backgroung: #ddd; color: #e03e2d; padding: 5px;\">Visit Advertiser</button></p>";
                                if (videoInfo.landingCtaType) {
                                    ctaDisplayContent = '<div class="customizedCTA_' + videoInfo.landingCtaType + '">';
                                    ctaDisplayContent += videoInfo.landingCtaIcon ? '<img src="' + videoInfo.landingCtaIcon + '" class="customizedCTAImg_' + videoInfo.landingCtaType + '"/>' : '';
                                    ctaDisplayContent += videoInfo.ctaContent ? '<span class="customizedCTATxt_' + videoInfo.landingCtaType + '">' + videoInfo.ctaContent + '</span>' : '';
                                    ctaDisplayContent += videoInfo.landingCtaBtnLabel ? '<button class="customizedCTABtn_' + videoInfo.landingCtaType + '">' + videoInfo.landingCtaBtnLabel + '</button></div>' : '';
                                }

                                let newLandingPageObj = {
                                    mainVideoId: videoInfo.id,
                                    landingPageURL: videoInfo.landingPage || '',
                                    landingPageDaggableProp: videoInfo.draggableProps ? JSON.parse(videoInfo.draggableProps) : {
                                        "height": 61,
                                        "width": 176,
                                        "cancel": "div",
                                        "grid": [
                                            1,
                                            1
                                        ],
                                        "defaultPosition": {
                                            "x": 0,
                                            "y": 308
                                        }
                                    },
                                    landingPageContent: ctaDisplayContent,
                                    landingCtaBtnLabel: videoInfo.landingCtaBtnLabel,
                                    landingCtaIcon: videoInfo.landingCtaIcon,
                                    landingCtaType: videoInfo.landingCtaType,
                                    ctaStatus: videoInfo.ctaStatus
                                };
                                setLandingPageObj(newLandingPageObj);
                                if (Number(videoInfo.ctaStatus) === 1) {
                                    setLandingPageObjVisible(true);
                                }
                            }
                        }
                    }
                }
                // // let adRequest = new XMLHttpRequest();

                // let url = encodeURI("http://adt.truereach.co.in/adserving/impag/ads?site-id=7e9b003c-9b11-11ed-bf1d-a5a5c37651ad&kdua=1&kdip=1&fmt=auto&ver=s2s_1&w=&h=&&ivrf=1");
                // adRequest.open("POST", url, false);
                // adRequest.send("");
                // adRequest.onreadystatechange = function () {
                //     if (adRequest.readyState == 4 && adRequest.status == 200) {
                //         console.log(adRequest.responseText);
                //     }
                // }
            } else {
                if (mainvideoId) {
                    const fetchInteractionData = Object.keys(interactionData).length ? false : true;
                    const response = await HttpRequest.GET_BY_ID_INI("/public/publicApi", mainvideoId, fetchInteractionData);
                    if (response.data.status === 'SUCCESS') {
                        let videoInfo = response.data.object.publicApi;
                        let images = videoInfo.imageList;
                        let hotspots = videoInfo.hotspotSettingList;
                        let text = videoInfo.textSettingList;
                        let sortedBranching = videoInfo.branchMappingList.sort((objectA, objectB) => parseFloat(objectA.mappingOrder) - parseFloat(objectB.mappingOrder));

                        let contentMedia = [...images, ...hotspots, ...text];
                        let sortedContentMedia = contentMedia.sort((objectA, objectB) => parseFloat(objectA.startTime) - parseFloat(objectB.startTime));

                        videoInfo.contentMedia = sortedContentMedia;
                        videoInfo.branchMappingList = sortedBranching;
                        document.getElementsByClassName('draggable_parent_div')[0].classList.remove('parentOverlay');
                        setPlayerVideoInfo(videoInfo); // set fetch id details into state object
                    }
                } else {
                    console.log("There have no id/null/undefined");
                }
            }
        } catch (error) {
            console.log("catch block warning : " + error);
        }
        return true;
    }

    const manageEvents = async (player, list, mainvideoId) => {
        try {
            // 
            let lastValue = 0;
            let listConMedia = list;
            // remove PictureInPictureToggle from the player controls
            player.controlBar.removeChild(player.controlBar.getChild('PictureInPictureToggle'));

            // player.on('loadedmetadata', function () {
            // })

            let playerVolumeStatus = 'M';

            let initialPlayEvent = false;
            let skipResumeTriggerFlag = false;
            let bigPlayButtonClicked = false;
            player.ready(function () {
                manageVideoInteraction('csc', mainvideoId);

                if (playerVideoInfo.videoRenderType === 1) {
                    manageInteraction(mainvideoId, 'video', 'start', 0, randomCurrentStr);
                    skipResumeTriggerFlag = true;
                    player.play();
                    playPauseVideo();
                    // if(playerVideoInfo.viewType === 1){
                    // }
                }

                if (playerVideoInfo.videoControls === 0) {
                    // player.controls(false);
                    document.getElementsByClassName('vjs-control-bar')[0].style.display = 'none';
                } else {
                    // player.controls(true);
                    document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex';
                }
                if (playerVideoInfo.seekbarControls === 0) {
                    document.getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = 'none';
                }

                let videoEnded = false;
                let videoLength_25 = true;
                let videoLength_50 = true;
                let videoLength_75 = true;

                function customHandler() {
                    if (!initialPlayEvent) {
                        initialPlayEvent = true;
                        removeSeekbarIcons();
                        // session used for after return switching duration is NaN
                        sessionStorage.setItem("duration", this.duration());
                        let intervalId = setInterval(() => {
                            let duration = this.duration();
                            if(duration) {
                                clearInterval(intervalId);
                                manageSeekbarIcon(duration, playerVideoInfo.contentMedia);                         
                            }
                        }, 1000);
                        // showTemplateHeader();
                    }
                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    if (videoEnded) {
                        return false;
                    }

                    const rountCurrentTime = Math.round(this.currentTime());
                    // console.table('lastValue, rountCurrentTime', lastValue, rountCurrentTime);
                    if (lastValue < rountCurrentTime) {
                        lastValue = rountCurrentTime; // set last value
                        try {
                            const renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
                            for (let i = 0; i < listConMedia.length; i++) {
                                if (String(listConMedia[i].videoId) === String(mainvideoId)) {
                                    // show interactions start
                                    if (rountCurrentTime === Number(listConMedia[i].startTime) && Number(listConMedia[i].startTime) > 0 && listConMedia[i].status == 1) {
                                        // debugger;
                                        let element = document.getElementById('template_div_id');
                                        // player.pause();

                                        // debugger

                                        if (element) {
                                            element.classList.add('hideAutoFixedTemp');
                                        }
                                        // hide branching on all interactions sj
                                        setBranchingMappingShowHide(false);

                                        if (Number(listConMedia[i].isClickRequired) === 1 && listConMedia[i].status == 1) {
                                            player.pause();
                                            setLandingPageObjVisible(false);
                                            player.currentTime(listConMedia[i].startTime);
                                            skipInteraction = true;
                                            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                                            // player.currentTime(listConMedia[i].startTime);
                                            if (playerVideoInfo.videoControls !== 0) {
                                                document.getElementsByClassName('vjs-control-bar')[0].style.display = 'none';
                                            }
                                        }
                                        manageInteraction(listConMedia[i].id, listConMedia[i].mediaType, 'IMPRESSION', 0, randomCurrentStr);
                                        if (listConMedia[i].mediaType === 'Form') {
                                            setBranchingMappingShowHide(false);
                                            setLandingPageObjVisible(false);
                                            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';

                                            // if (fixedTemplateContent) {
                                            //     setFixedTemplateVisible(false);
                                            // }

                                            let element = document.getElementById('template_div_id');
                                            if (element) {
                                                element.classList.add('hideAutoFixedTemp');
                                            }
                                        }

                                        if (Number(listConMedia[i].isPauseEnable) === 1 && isEditMode === false && Number(listConMedia[i].isClickRequired) !== 1) {
                                            player.currentTime(listConMedia[i].startTime);
                                            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                                            pauseVideoSeconds(player, listConMedia[i], renderHtmlConMedia[i]);
                                        }
                                        if (listConMedia[i].mediaType !== 'Form') {
                                            if (Number(listConMedia[i].status === 1)) {
                                                renderHtmlConMedia[i].style.display = "block";
                                            }
                                        } else {
                                            if (Number(listConMedia[i].status === 1)) {
                                                renderHtmlConMedia[i].style.display = "flex";
                                                document.getElementById('quizCross_' + i).style.display = 'block';
                                            }
                                        }
                                    }
                                    // show interactions end
                                    if (rountCurrentTime < Number(listConMedia[i].startTime) && Number(listConMedia[i].startTime) > 0 && listConMedia[i].status == 1) {
                                        renderHtmlConMedia[i].style.display = "none";
                                        // if (listConMedia[i].mediaType === 'Form') {
                                        //     document.getElementById('quizCross_'+ i ).style.display = 'none';
                                        // }


                                    }

                                    // End Time Match
                                    if (listConMedia[i].isClickRequired !== 1 && listConMedia[i].isPauseEnable !== 1 && rountCurrentTime >= Number(listConMedia[i].endTime) && Number(listConMedia[i].endTime) > 0) {
                                        renderHtmlConMedia[i].style.display = "none";
                                        // if (listConMedia[i].mediaType === 'Form') {
                                        //     document.getElementById('quizCross_'+ i ).style.display = 'none';
                                        // }
                                        let element = document.getElementById('template_div_id');
                                        if (element) {
                                            element.classList.remove('hideAutoFixedTemp');
                                        }

                                    }
                                }
                            }
                        } catch (error) { console.log(error) }
                    } else {
                        lastValue = rountCurrentTime; // set last value
                    }

                    let videoDuration = (this.duration() - 1);
                    let duration = '';
                    let currentTime = (Math.floor(this.currentTime()));
                    if (videoDuration > 0 && (currentTime == (Math.floor(videoDuration / 4))) && videoLength_25) {
                        console.log('25% reached');
                        manageInteraction(mainvideoId, 'video', 'firstQuartile', currentTime, randomCurrentStr);
                        videoLength_25 = false;
                    }
                    if (videoDuration > 0 && (currentTime == (Math.floor(videoDuration / 2))) && videoLength_50) {
                        console.log('50% reached');
                        duration = (currentTime - (Math.floor(videoDuration / 4)));
                        manageInteraction(mainvideoId, 'video', 'midpoint', duration, randomCurrentStr);
                        videoLength_50 = false;
                    }
                    if (videoDuration > 0 && (currentTime == (Math.floor(videoDuration * 3 / 4))) && videoLength_75) {
                        console.log('75% reached');
                        duration = (currentTime - (Math.floor(videoDuration / 2)));
                        manageInteraction(mainvideoId, 'video', 'thirdQuartile', duration, randomCurrentStr);
                        videoLength_75 = false;
                    }
                    if (videoDuration > 0 && (currentTime == (Math.floor(videoDuration * 4 / 4)))) {
                        console.log('100% reached');
                        // duration = (currentTime - (Math.floor(videoDuration * 3 / 4)));
                        // manageInteraction(mainvideoId, 'video', 'complete', duration);
                        // videoEnded = true;
                    }
                }

                this.on(player, 'timeupdate', customHandler);// update time end

                this.bigPlayButton.on('click', function () {
                    console.log("click Big Play Button");
                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    manageInteraction(mainvideoId, 'video', 'start', 0, randomCurrentStr);
                    skipResumeTriggerFlag = true;
                    bigPlayButtonClicked = true;
                });

                this.on('play', function () {

                    if (fixedTemplateContent) {
                        setFixedTemplateVisible(true);
                    }

                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    console.log('play called', mainvideoId)
                    if (pauseSecModeTimerId) {
                        clearTimeout(pauseSecModeTimerId);
                        setPauseSecModeTimerId('');
                    }
                    if (playerVideoInfo.videoRenderType === 0) {
                        if (!bigPlayButtonClicked) {
                            manageInteraction(mainvideoId, 'video', 'start', 0, randomCurrentStr);
                            skipResumeTriggerFlag = false;
                        } else {
                            if (!skipResumeTriggerFlag) {
                                manageInteraction(mainvideoId, 'video', 'resume', 0, randomCurrentStr);
                                // skipResumeTriggerFlag = true;
                            }
                        }
                        bigPlayButtonClicked = true;
                    } else {
                        if (!skipResumeTriggerFlag) {
                            manageInteraction(mainvideoId, 'video', 'resume', 0, randomCurrentStr);
                            // skipResumeTriggerFlag = true;
                        } else {
                            skipResumeTriggerFlag = false;
                        }
                    }
                    setShowBranching(true);
                    skipInteraction = false;
                    // setTotalDuration(this.duration());
                    // manageSeekbarIcon(this.duration(), listConMedia);
                });

                this.on('pause', function () {
                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    console.log('pause called');
                    manageInteraction(mainvideoId, 'video', 'pause', 0, randomCurrentStr);
                    if (skipResumeTriggerFlag) {
                        skipResumeTriggerFlag = false;
                    }
                    skipInteraction = false;
                });

                this.on('ended', function () {
                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    console.log('ended called');
                    let videoDuration = (this.duration() - 1);
                    let currentTime = (Math.floor(this.currentTime()));
                    let duration = (currentTime - (Math.floor(videoDuration * 3 / 4)));
                    manageInteraction(mainvideoId, 'video', 'complete', duration, randomCurrentStr);
                });

                this.on("seeked", function (event) {
                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    console.log("seeked called");
                });

                player.on('volumechange', function () {
                    if (randomCurrentStr != localStorage.getItem('randomCurrentStr' + video_id)) {
                        return true;
                    }
                    if (this.muted() && playerVolumeStatus == 'NM') {
                        // fire mute
                        manageInteraction(mainvideoId, 'video', 'mute', 0, randomCurrentStr);
                        playerVolumeStatus = 'M';
                    }
                    if (!this.muted() && playerVolumeStatus == 'M') {
                        // fire unmute
                        playerVolumeStatus = 'NM';
                        manageInteraction(mainvideoId, 'video', 'unmute', 0, randomCurrentStr);
                        // console.log('fire not mute');
                    }
                });
            });
        } catch (e) { console.log(e); }
        return true;
    }

    // 1 - Track & Continue
    // 2 - Open Link
    // 5 - Show Message
    const clickAction = (object, i, event) => {
        // debugger;
        try {
            event.preventDefault();
            // event.stopPropagation();
            let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
            // if (!pauseSecMode) {
            if (clickActionEnable === true) {
                document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';

                if (Number(object.actionType) === 2) {
                    // player.play();
                    window.open(object.actionContent, "_blank");
                    playPauseVideo();
                    setBranchingMappingShowHide(true);
                    setLandingPageObjVisible(true);
                    let element = document.getElementById('template_div_id');
                    if (element) {
                        element.classList.remove('hideAutoFixedTemp');
                    }
                } else if (Number(object.actionType) === 3) {
                    setReturnBtnVisible(false);
                    setBranchingMappingShowHide(false);
                    setFixedTemplateVisible(false);

                    let actionContentInfo = JSON.parse(object.actionContent);
                    setTimeout(() => { setReturnBtnVisible(true); }, 1000);
                    getVideoById(player, actionContentInfo.value);
                    removeSeekbarIcons();

                    setTimeout(() => {
                        player.play();
                        setBranchingMappingShowHide(true);
                        setFixedTemplateVisible(true);
                    }, 500);

                } else if (Number(object.actionType) === 6) {
                    if (pauseSecModeTimerId) {
                        clearTimeout(pauseSecModeTimerId);
                        setPauseSecModeTimerId('');
                    }

                    let previousElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
                    previousElement.style.display = 'none';

                    let actionContentInfo = JSON.parse(object.actionContent);
                    let imageData = null;
                    let imageList = playerVideoInfo.imageList;

                    if (imageList.length > 0 && actionContentInfo) {
                        for (let i = 0; i < imageList.length; i++) {
                            const element = imageList[i];
                            if (Number(element.id) === Number(actionContentInfo.value)) {
                                imageData = element;
                            }

                        }

                        let element = document.getElementById(`div_${imageData.mediaType}_${imageData.id}`);
                        element.style.display = 'block';

                        // desable when image 
                        player.pause();
                        // player.controls(false);
                        setBranchingMappingShowHide(false);
                        // setFixedTemplateVisible(false);

                    }
                    let element = document.getElementById('template_div_id');
                    if (element) {
                        element.classList.add('hideAutoFixedTemp');
                    }


                } else if (Number(object.actionType) === 7 || Number(object.actionType) === 8) { //Form & Quiz Switching
                    if (pauseSecModeTimerId) {
                        clearTimeout(pauseSecModeTimerId);
                        setPauseSecModeTimerId('');
                    }

                    let previousElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
                    previousElement.style.display = 'none';

                    let actionContentInfo = JSON.parse(object.actionContent);
                    let data = null;
                    let formList = playerVideoInfo.formSettingsList;

                    if (formList.length > 0) {
                        for (let i = 0; i < formList.length; i++) {
                            const element = formList[i];
                            if (Number(element.id) === Number(actionContentInfo.value)) {
                                data = element;
                            }

                        }

                        let element = document.getElementById(`div_${data.mediaType}_${data.id}`);
                        element.style.display = 'flex';
                        // reset the form fields
                        let formTagObj;
                        // debugger;
                        if (data.formType === 2) {
                            formTagObj = document.getElementById(`quiz_${data.id}`);
                        } else {
                            formTagObj = document.getElementById(`form_${data.id}`);
                        }
                        formTagObj.reset();
                        let formIndex;
                        playerVideoInfo.contentMedia?.map((object, index) => {
                            if (object.id == data.id) {
                                formIndex = index;
                            }
                        })
                        let newRadioValueArr = { ...radioValueArr };
                        data.fields.map((formField, fieldIndex) => {
                            if (formField.type === 3) {
                                // JSON.parse(formField.options).map((radioField, radioFieldIndex) =>{
                                //     document.getElementById('radioIndex_'+ data.id +'-'+ fieldIndex +'-'+ radioFieldIndex).checked = false;
                                //     console.log(radioField);
                                // })
                                newRadioValueArr[formIndex + '_' + fieldIndex] = '';
                            }
                        })
                        setRadioValueArr(newRadioValueArr);
                        quizIndexObj[data.id] = 0;
                        setQuizIndexObj({ ...quizIndexObj });

                        // let radioReset = document.querySelectorAll("input[type=radio]");
                        // for(i=0;i<radioReset.length;i++){
                        //     radioReset[i].checked=false;
                        // }
                        // desable when image 
                        player.pause();
                        // player.controls(false);
                        setBranchingMappingShowHide(false);
                        // setFixedTemplateVisible(false);
                    }
                    let element = document.getElementById('template_div_id');
                    if (element) {
                        element.classList.add('hideAutoFixedTemp');
                    }
                } else if (Number(object.actionType) === 5) { //Show Msg 
                    if (pauseSecModeTimerId) {
                        clearTimeout(pauseSecModeTimerId);
                        setPauseSecModeTimerId('');
                    }
                    skipInteraction = true;
                    player.pause();
                    setLandingPageObjVisible(false);
                    // player.controls(false);
                    document.getElementsByClassName('vjs-control-bar')[0].style.display = 'none';
                    setAction({ 'object': object, 'actionContent': object.actionContent });
                    document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                    setShowMsgEnable(true);
                    // if (fixedTemplateContent) {
                    //     setFixedTemplateVisible(false);
                    // }
                } else if (object.actionType == 1 || object.actionType == -1 || !object.actionType || object.actionType == "") {
                    if (pauseSecModeTimerId) {
                        clearTimeout(pauseSecModeTimerId);
                        setPauseSecModeTimerId('');
                    }
                    skipInteraction = true;
                    player.play();
                    document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex';
                    setBranchingMappingShowHide(true);
                    setLandingPageObjVisible(true);
                    let element = document.getElementById('template_div_id');
                    if (element) {
                        element.classList.remove('hideAutoFixedTemp');
                    }
                }
            }
            // }
            renderHtmlConMedia[i].style.display = "none";

            // play video if there have no any switching
            // if (Number(object.isClickRequired) === 1 && !isEditMode) {
            // renderHtmlConMedia[i].setAttribute("style", "display:none;");
            // }
        } catch (e) { console.log(e); }
        return true;
    }

    function pauseVideoSeconds(player, mediaObject, htmlRenderMedia) {
        try {
            setPauseSecMode(true);
            setLandingPageObjVisible(false);
            skipInteraction = true;
            player.pause();
            player.currentTime(mediaObject.startTime);
            // player.controls(false);
            document.getElementsByClassName('vjs-control-bar')[0].style.display = 'none';

            // manage branching when any content is paused
            setBranchingMappingShowHide(false);
            console.log('setTimeout called');
            let timerId = setTimeout(() => {
                // if (isEditMode === false) {
                player.play();
                setLandingPageObjVisible(true);
                // player.controls(true);
                if (playerVideoInfo.videoControls !== 0) {
                    document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex';
                }
                document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
                setBranchingMappingShowHide(true);
                htmlRenderMedia.style.display = "none";
                setPauseSecMode(false);
            }, Number(mediaObject.pauseLength));
            setPauseSecModeTimerId(timerId);
        } catch (e) { console.log(e); }
        return true;
    }

    function manageSeekbarIcon(duration, list) {
        try {
            // get main video duration form session if duration is NaN
            if (!duration) {
                duration = sessionStorage.getItem("duration");
                sessionStorage.removeItem("duration");
            }
            // 
            let element = document.getElementsByClassName('vjs-load-progress')[0];
            list.map(function (data, index) {

                const type = data.mediaType;
                const startTime = Number(data.startTime);
                const percent = ((startTime / duration) * 100) - 2;

                if (Number(data.status) !== 1 || startTime <= 0) {
                    return false;
                }
                switch (type) {
                    case 'image':
                        element.insertAdjacentHTML('beforebegin', "<img src=\"/" + helper.baseURL() + "/assets/icon_images_2.png\" class=\"my-image\" style=\"padding:2px;left: " + percent + "%; width: " + calculatedIconWidth + "px; \"/>");
                        break;
                    case 'hotspot':
                        element.insertAdjacentHTML('beforebegin', "<img src=\"/" + helper.baseURL() + "/assets/icon_hotspot_2.png\" class=\"my-hotspot\" style=\"left: " + percent + "%; width: " + calculatedIconWidth + "px;\"/>");
                        break;
                    case 'TXT':
                        element.insertAdjacentHTML('beforebegin', "<img src=\"/" + helper.baseURL() + "/assets/icon_text_2.png\" class=\"my-text\" style=\"left: " + percent + "%; width: " + calculatedIconWidth + "px;\"/>");
                        break;
                    case 'Form':
                        element.insertAdjacentHTML('beforebegin', "<img src=\"/" + helper.baseURL() + "/assets/icon_form_1.png\" class=\"my-form\" style=\"left: " + percent + "%; width: " + calculatedIconWidth + "px;\"/>");
                        break;
                    default:
                        break;
                }
            });
        } catch (e) { console.log(e) }
        return true;
    }

    const processInteractionData = function () {
        try {
            playerVideoInfo.videoInteractionList.map((interaction, interactionIndex) => {
                let interactionType = interaction.interactionType;
                let promptType = interaction.promptType;
                let promptId = interaction.promptId;
                let interactionId = promptType === 'video' ? promptId : interaction.id;
                if (!interactionData.video) {
                    interactionData.video = {};
                    // interactionData.video.mainVideoId = String(playerVideoInfo.id);
                    // interactionData.video.currentVideoId = String(playerVideoInfo.id);
                    // interactionData.video.prevVideoId = '';
                    console.log('currentVideoId' + '-' + video_id + '__' + siteId + '__' + randomStr);
                    localStorage.setItem('currentVideoId' + '-' + video_id + '__' + siteId + '__' + randomStr, String(playerVideoInfo.id));
                }
                if (playerVideoInfo.interactionEventData) {
                    for (const [key, value] of Object.entries(playerVideoInfo.interactionEventData[0].video)) {
                        if (!interactionData.video.key) {
                            interactionData.video[key] = value;
                        }
                        if (!interactionData.interactions) {
                            interactionData.interactions = {};
                        }
                        interactionData.interactions[`${promptId}_${promptType}_${interactionType}`] = interaction;
                        if (value.tevents[interaction.id]) {
                            interactionData.interactions[`${promptId}_${promptType}_${interactionType}`]['interaction'] = value.tevents[interaction.id];
                            interactionData.interactions[`${promptId}_${promptType}_${interactionType}`]['interaction'] = interactionData.interactions[`${promptId}_${promptType}_${interactionType}`]['interaction'].map((int, intIndex) => {
                                return int.replace('ttype=video', 'ttype=' + interactionData.interactions[`${promptId}_${promptType}_${interactionType}`].promptType);
                            });
                        }
                    }
                } else {
                    interactionData.interactions[`${promptId}_${promptType}_${interactionType}`] = interaction;
                    if (interactionData.video[`${playerVideoInfo.id}`]?.tevents[interaction.id]) {
                        interactionData.interactions[`${promptId}_${promptType}_${interactionType}`]['interaction'] = interactionData.video[`${playerVideoInfo.id}`].tevents[interaction.id];
                        interactionData.interactions[`${promptId}_${promptType}_${interactionType}`]['interaction'] = interactionData.interactions[`${promptId}_${promptType}_${interactionType}`]['interaction'].map((int, intIndex) => {
                            return int.replace('ttype=video', 'ttype=' + interactionData.interactions[`${promptId}_${promptType}_${interactionType}`].promptType);
                        });
                    }
                }
            })
            setInteractionData(interactionData);
        } catch (e) { console.log(e); }
        return true;
    }

    const manageInteraction = function (interactionId, promptType, interactionType, duration = 0) {
        try {
            // commented on 6Apr23 as this was causing issue because player pause and play are promise based.
            // if (skipInteraction) {
            //     skipInteraction = false;
            //     console.log('interaction skipped ', promptType, interactionType);
            //     return true;
            // }
            if (interactionType === 'start') {
                setVideoStarted(true);
            }
            if (tagStatus === 'DRAFT') {
                return true;
            }
            if (interactionId && promptType && interactionType
                && localStorage.getItem('currentVideoId' + '-' + video_id + '__' + siteId + '__' + randomStr) == playerVideoInfo.id
                && randomCurrentStr == localStorage.getItem('randomCurrentStr' + video_id)) {
                interactionData.interactions[`${interactionId}_${promptType}_${interactionType}`]?.interaction?.map((url, index) => {
                    let imgIrack = document.createElement('img');
                    let updatedUrl = url.replace('&tevent', '_wt_' + duration + '&tevent');
                    updatedUrl += '&t=' + new Date().getTime();
                    let cookieValue = getCookie('tr-interactive');
                    if (updatedUrl.indexOf('?') < 0) {
                        imgIrack.src = updatedUrl + ('?KRITTER_USER_ID=' + cookieValue);
                    } else {
                        imgIrack.src = updatedUrl + ('&KRITTER_USER_ID=' + cookieValue);
                    }
                });
            }
            // if (interactionType === 'start') {
            //     setVideoStarted(true);
            // }
        } catch (e) {
            console.log(e);
        }
        return true;
    }

    const manageVideoInteraction = function (type, videoId) {
        try {
            if (tagStatus === 'DRAFT') {
                return true;
            }
            let url = '';
            if (type === 'clk' && !clkTrackerFired) {
                url = interactionData.video[videoId]?.clk;
            } else if (type === 'csc' && videoIdsArr.indexOf(videoId) < 0) {
                url = interactionData.video[videoId]?.csc;
            } else if (type === 'resp' && videoIdsArr.indexOf(videoId) < 0) {
                url = interactionData.video[videoId]?.resp;
            } else if (type === 'gclckunesc') {
                url = gclckunesc;
            }
            if (url) {
                let imgIrack = new Image();
                let cookieValue = getCookie('tr-interactive');
                if (url.indexOf('?') < 0) {
                    imgIrack.src = url + ('?KRITTER_USER_ID=' + cookieValue);
                } else {
                    imgIrack.src = url + ('&KRITTER_USER_ID=' + cookieValue);
                }
                if (type === 'clk') {
                    setClkTrackerFired(true);
                }
            }
        } catch (e) { console.log(e); }
        return true;
    }

    const manageTemplateInteraction = function (event) {
        try {
            let dataAttr = event.target.getAttribute('data-tr');
            if (dataAttr) {
                manageInteraction(fixedTemplateId, 'template', dataAttr);
            }
        } catch (e) { console.log(e) }
    }

    const manageBranchingTemplateInteraction = function (event) {
        try {
            let dataAttr = event.target.getAttribute('data-tr');
            if (dataAttr) {
                manageInteraction(branchTemplateId, 'template', dataAttr);
            }
        } catch (e) { console.log(e) }
    }

    const returnToPrevVideo = function (event) {
        try {
            event.stopPropagation();
            let player = videojs('my-video');
            let interactionDataNew = { ...interactionData };
            if (interactionDataNew.video.prevVideoId) {
                // 
                interactionDataNew.video.currentVideoId = interactionDataNew.video.prevVideoId.split(',')[interactionDataNew.video.prevVideoId.split(',').length - 1];
                localStorage.setItem('currentVideoId' + '-' + video_id + '__' + siteId + '__' + randomStr, interactionDataNew.video.currentVideoId);
                let prevVideoIdArr = interactionDataNew.video.prevVideoId.split(',');
                prevVideoIdArr.pop();
                interactionDataNew.video.prevVideoId = prevVideoIdArr.join(',');
                if (interactionDataNew.video.mainVideoId == interactionDataNew.video.currentVideoId) {
                    setReturnBtnVisible(false);
                }
                setInteractionData(interactionDataNew);

                if (templateVisible) {
                    player.play();
                    setTemplateVisible(false);
                    setFixedTemplateVisible(true);
                } else {
                    getVideoById(player, interactionDataNew.video.currentVideoId);
                    setTimeout(() => { player.play() }, 1000);
                }
            }
        } catch (e) { console.log(e) }
        return true;
    }

    const returnToMainVideo = function (event) {
        try {
            event.stopPropagation();

            let playerURLHistoryNew = { ...playerURLHistory };

            let player = videojs('my-video');
            let mainVideoId = playerURLHistoryNew.mainVideoId;
            playerURLHistoryNew.currentVideoId = playerURLHistoryNew.mainVideoId;
            localStorage.setItem('currentVideoId' + '-' + video_id + '__' + siteId + '__' + randomStr, playerURLHistoryNew.currentVideoId);
            playerURLHistoryNew.prevVideoId = '';
            if (playerURLHistoryNew.mainVideoId == playerURLHistoryNew.currentVideoId) {
                setReturnBtnVisible(false);
            }
            setPlayerURLHistory(playerURLHistoryNew);

            if (templateVisible) {
                player.play();
                setTemplateVisible(false);
                setFixedTemplateVisible(true);
            } else {
                getVideoById(player, mainVideoId);
                setTimeout(() => { player.play() }, 1000);
            }
            if (fixedTemplateVisible) {
                setFixedTemplateVisible(false);
                // setReturnBtnVisible(false);
                // showTemplateHeader(playerVideoInfo, true); // set Header
            }
        } catch (e) { console.log(e) }
        return true;
    }

    const triggerLandingURL = function (event) {
        try {
            event.stopPropagation();
            let url = landingPageObj.landingPageURL;
            if (url) {
                window.open(url, "_blank");
                manageVideoInteraction('clk', landingPageObj.mainVideoId);
                if (gclckunesc) {
                    manageVideoInteraction('gclckunesc', landingPageObj.mainVideoId);
                }

            }
        } catch (e) { console.log(e) }
        return true;
    }

    function playPauseVideo() {
        try {
            let videos = document.querySelectorAll("video");//todo change selector to select only current video
            videos.forEach((video) => {
                // We can only control playback without insteraction if video is mute
                if (playerVideoInfo.id === video_id) {
                    video.muted = true;
                }
                // Play is a promise so we need to check we have it
                let playPromise = video.play();
                if (playPromise !== undefined) {
                    playPromise.then((_) => {
                        let iniFlag = true;
                        let observer = new IntersectionObserver(
                            (entries) => {
                                // 
                                entries.forEach((entry) => {
                                    if (
                                        entry.intersectionRatio !== 1 &&
                                        !video.paused
                                    ) {
                                        if (playerVideoInfo.outOfView === 0 || iniFlag) {
                                            skipInteraction = true;
                                            video.pause();
                                            iniFlag = false;
                                        }
                                    } else if (video.paused) {
                                        video.play();
                                    }
                                });
                            },
                            { threshold: parseInt(playerVideoInfo.inView) / 100 }
                        );
                        observer.observe(video);
                    });
                }
            });
        } catch (e) { console.log(e); }
        return true;
    }

    function manageRadioValueChange(index, fieldIndex, option, optionIndex) {
        try {
            let newRadioValueArr = { ...radioValueArr };
            newRadioValueArr[index + '_' + fieldIndex] = option.value;
            setRadioValueArr(newRadioValueArr);
            console.log(index, fieldIndex, option, optionIndex, newRadioValueArr);
        } catch (e) { console.log(e); }
    }

    async function manageQuizFieldData(event, object, index, fieldIndex) {
        try {
            event.stopPropagation();
            event.preventDefault();
            let optionsArr = JSON.parse(object.fields[fieldIndex].options);
            let correctAnswer = '';
            let userAnswer = '';
            optionsArr.map((option, optionIndex) => {
                if (option.is_correct || object.fields[fieldIndex].type == 1 || object.fields[fieldIndex].type == 5 || object.fields[fieldIndex].type == 6) {
                    correctAnswer = correctAnswer ? correctAnswer + ',' + option.value : option.value;
                }
            });
            console.log('correctAnswer ', correctAnswer);
            document.querySelectorAll('.mandatoryMsg')?.forEach((e) => e.remove());
            document.querySelectorAll('.fieldMandatory')?.forEach((e) => e.classList.remove('fieldMandatory'));
            if ([1, 2, 5, 6].indexOf(object.fields[fieldIndex].type) >= 0) {
                userAnswer = event.target[0].value;
                if (!userAnswer && object.fields[fieldIndex].isMandatory) {
                    event.target[0].classList.add('fieldMandatory');
                    let validationElement = document.createElement('p');
                    validationElement.className = 'mandatoryMsg';
                    validationElement.innerHTML = object.fields[fieldIndex].validationMsg || 'Please select some value!';
                    event.target[0].parentElement.appendChild(validationElement);
                    return false;
                } else {
                    event.target[0].classList.remove('fieldMandatory');
                }
                console.log('userAns ', userAnswer);
                if (userAnswer && correctAnswer.split(',').indexOf(userAnswer) >= 0) {
                    object.fields[fieldIndex].isCorrect = true;
                    console.log('correct');
                } else {
                    object.fields[fieldIndex].isCorrect = false;
                }
            } else {
                for (let i = 0; i < optionsArr.length; i++) {
                    if (event.target[i].checked) {
                        event.target[i].classList.remove('fieldMandatory');
                        userAnswer = userAnswer ? userAnswer + ',' + event.target[i].value : event.target[i].value;
                    }
                }
                if (!userAnswer && object.fields[fieldIndex].isMandatory) {
                    for (let i = 0; i < optionsArr.length; i++) {
                        if (!event.target[i].checked) { //not necesssary, can be removed
                            event.target[i].classList.add('fieldMandatory');
                        }
                    }
                    let validationElement = document.createElement('p');
                    validationElement.className = 'mandatoryMsg';
                    validationElement.innerHTML = object.fields[fieldIndex].validationMsg || 'Please select some value!';
                    event.target.appendChild(validationElement);
                    return false;
                }
                console.log('userAns ', userAnswer);
                object.fields[fieldIndex].isCorrect = false;
                userAnswer.split(',').map((userAns) => {
                    if (userAnswer && correctAnswer.split(',').indexOf(userAns) >= 0) {
                        object.fields[fieldIndex].isCorrect = true;
                        console.log('correct');
                    }
                });
            }
            object.fields[fieldIndex].userAnswer = { 'correctAnswer': correctAnswer, 'userAnswer': userAnswer };
            if (object.fields.length - 1 > fieldIndex) {
                // setQuizFieldIndex(++fieldIndex);
                quizIndexObj[object.id] = quizIndexObj[object.id] + 1;
                setQuizIndexObj({ ...quizIndexObj });
                // object.iniFieldIndex = object.iniFieldIndex + 1;
            } else {
                let score = 0;
                let totalScore = object.fields.length;
                object.fields.map((field) => {
                    if (field.isCorrect) {
                        score++;
                    }
                    return true;
                });
                setquizFormScore('<p>' + 'You scored ' + score + ' points out of ' + totalScore + '!' + '</p>')
                setTimeout(() => {
                    setquizFormScore('');
                }, 2000);
                // window.confirm('You scored ' + score + ' points out of ' + totalScore + '!');

                manageInteraction(object.id, object.mediaType, 'CLICK');
                // let userQuizData = object.fields.map((field, fieldIndex) => {
                //     return { [fieldIndex]: field.userAnswer };
                // });
                let element = document.getElementById('template_div_id');
                if (element) {
                    element.classList.remove('hideAutoFixedTemp');

                }

                let formMethod = object.formMethod;
                let formActionUrl = object.formActionUrl;

                try {
                    if (formActionUrl) {
                        if (formMethod == 0) {
                            let userQuizData = '?';
                            // 
                            object.fields.map((field, fieldIndex) => {
                                let fieldKey = field.fieldName || 'key';
                                userQuizData += fieldKey + '=' + field.userAnswer.userAnswer;
                                userQuizData += '&';
                            });
                            const response = await HttpRequest.GET(formActionUrl + userQuizData);
                            // console.log(formActionUrl + userQuizData);
                            console.log(response);
                        } else if (formMethod == 1) {
                            let userQuizData = {};
                            object.fields.map((field, fieldIndex) => {
                                let fieldKey = field.fieldName || 'key-' + fieldIndex;
                                userQuizData = { ...userQuizData, [fieldKey]: field.userAnswer.userAnswer };
                            });
                            const response = await HttpRequest.POST(formActionUrl, userQuizData);
                            console.log(response);
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
                finally {
                    // setQuizFieldIndex(0);
                    // object.iniFieldIndex = 0;
                }
                clickAction(object, index, event);
            }
        } catch (e) { console.log(e); }
        return true;
    }

    async function handleFormSubmit(event, object, index) {
        try {
            event.preventDefault();
            event.stopPropagation();

            let eventInputIndex = 0;
            let userAnswer = '';
            let validationFailed = false;
            document.querySelectorAll('.mandatoryMsg')?.forEach((e) => e.remove());
            document.querySelectorAll('.fieldMandatory')?.forEach((e) => e.classList.remove('fieldMandatory'));
            object.fields.map((fieldData, fieldIndex) => {
                userAnswer = '';
                let optionsArr = JSON.parse(fieldData.options);
                if ([1, 2, 5, 6].indexOf(object.fields[fieldIndex].type) >= 0) {
                    userAnswer = event.target[eventInputIndex].value;
                    event.target[eventInputIndex].classList.remove('fieldMandatory');
                    if (!userAnswer && object.fields[fieldIndex].isMandatory) {
                        event.target[eventInputIndex].classList.add('fieldMandatory');
                        let validationElement = document.createElement('p');
                        validationElement.className = 'mandatoryMsg';
                        validationElement.innerHTML = object.fields[fieldIndex].validationMsg || 'Please select some value!';
                        event.target[eventInputIndex].parentElement.appendChild(validationElement);
                        validationFailed = true;
                    }
                    eventInputIndex++;
                } else {
                    let iniEventIndex = eventInputIndex;
                    optionsArr.map((option, optionIndex) => {
                        if (event.target[eventInputIndex].checked) {
                            userAnswer = event.target[eventInputIndex].value;
                            event.target[eventInputIndex].classList.remove('fieldMandatory');
                        }
                        eventInputIndex++;
                    });
                    if (!userAnswer && object.fields[fieldIndex].isMandatory) {
                        optionsArr.map((option, optionIndex) => {
                            event.target[iniEventIndex].classList.add('fieldMandatory');
                            validationFailed = true;
                            iniEventIndex++;
                        });
                        let validationElement = document.createElement('p');
                        validationElement.className = 'mandatoryMsg';
                        validationElement.innerHTML = object.fields[fieldIndex].validationMsg || 'Please select some value!';
                        event.target[--iniEventIndex].parentElement.parentElement.appendChild(validationElement);
                    }
                }
                object.fields[fieldIndex].userAnswer = userAnswer;
            });
            // 
            if (validationFailed) {
                console.log('validationFailed');
                return false;
            }

            manageInteraction(object.id, object.mediaType, 'CLICK');
            // if (fixedTemplateContent) {
            //     setFixedTemplateVisible(true);
            // }
            let element = document.getElementById('template_div_id');
            if (element) {
                element.classList.remove('hideAutoFixedTemp');

            }
            let formMethod = object.formMethod;
            let formActionUrl = object.formActionUrl;

            if (formActionUrl) {
                if (formMethod == 0) {
                    let userQuizData = '?';
                    object.fields.map((field, fieldIndex) => {
                        let fieldKey = field.fieldName || 'key';
                        userQuizData += fieldKey + '=' + field.userAnswer;
                        userQuizData += '&';
                    });
                    const response = await HttpRequest.GET(formActionUrl + userQuizData);
                    console.log(response);
                } else if (formMethod == 1) {
                    let userQuizData = {};
                    object.fields.map((field, fieldIndex) => {
                        let fieldKey = field.fieldName || 'key-' + fieldIndex;
                        userQuizData = { ...userQuizData, [fieldKey]: field.userAnswer };
                    });
                    const response = await HttpRequest.POST(formActionUrl, userQuizData);
                    console.log(response);
                }
            }
        } catch (e) {
            console.log(e);
            // return false;
        }
        clickAction(object, index, event);
        // finally {
        // }
        return true;

    }

    const showTemplateHeader = async (playerVideoInfo, isFirstRequest) => {
        const data = playerVideoInfo.branchMappingList;

        // find template based on priority
        // let templateObj = null;
        // data.map((object, index) => {
        //     let flag = false;
        //     if (object.mappingType === 1 && !flag) { flag = true; templateObj = object; }
        // });

        let fixedTemplateData = {};
        let templateId = '';
        playerVideoInfo.templateVideoList.map((templateData, index) => {
            if (templateData.templateType === 1) {
                fixedTemplateData = templateData;
                templateId = templateData.id;
            }
        });
        // console.log(fixedTemplateData);

        const scriptTag = await makeJs(fixedTemplateData);
        if (scriptTag) {
            // debugger;
            let myInterval = setInterval(templateDivInterval, 100);
            function templateDivInterval() {
                if (document.getElementsByClassName('template_div')[0]) {
                    document.head.appendChild(scriptTag);
                    clearInterval(myInterval);
                }
            }
        }

        if (isFirstRequest) {
            setFixedTemplateVisible(true);
        }

        setTemplateVisible(false);
        setFixedTemplateContent(fixedTemplateData.fixedContent);
        setFixedTemplateId(templateId);
        // returnBtnVisible(true);
    }


    const makeJs = async (templateDataById) => {
        // Make Javascript for template
        const scriptTag = document.createElement('script');
        scriptTag.id = "template_js";
        scriptTag.type = "text/javascript";
        // debugger;
        scriptTag.text = templateDataById.templateJs;
        scriptTag.crossOrigin = "anonymous";
        scriptTag.async = true;
        scriptTag.defer = true;
        return scriptTag;
    }

    const closeFixedTemplate = () => {
        setFixedTemplateVisible(false);
        setReturnBtnVisible(false);
        showTemplateHeader(playerVideoInfo, true); // set Header
        // player.play();
    }
    function closeForm(index) {
        document.querySelectorAll('.mandatoryMsg')?.forEach((e) => e.remove());
        document.querySelectorAll('.fieldMandatory')?.forEach((e) => e.classList.remove('fieldMandatory'));
        setShowMsgEnable(false);
        player.play();
        setLandingPageObjVisible(true);
        let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
        renderHtmlConMedia[index].style.display = "none";
        // document.getElementById('quizCross_' + index).style.display = 'none';
        document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
        // player.controls(true);
        setBranchingMappingShowHide(true);

        // if (fixedTemplateContent) {
        //     setFixedTemplateVisible(false);
        // }
        let element = document.getElementById('template_div_id');
        if (element) {
            element.classList.remove('hideAutoFixedTemp');
        }
        if (playerVideoInfo.videoControls !== 0) {
            document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex';
        }
    }
    // function RenderRadio (props) {
    //     return (
    //         <div style={{ display: 'flex', marginBottom:'10px'}} onChange={(event) => { props.object.fields[props.quizFieldIndex].selected = event.target.value;console.log(event.target.value, props.object)}}>
    //         {JSON.parse(props.object.fields[props.quizFieldIndex].options).map((quizOption, quizOptionIndex) => {
    //             return (
    //                 // <div key={quizOptionIndex} style={{ display: 'flex', marginBottom:'10px'}} >
    //                     <>
    //                         {quizOption.value}<input key={quizOptionIndex} type='radio' value={quizOption.value} checked={props.object.fields[props.quizFieldIndex].selected == quizOption.value}/>
    //                     </>
    //                 // </div>
    //             )
    //         })}
    //         </div>
    //     )
    // }

    function includeSlotResponsiveCss() {
        //  load responsive css acoording to player size
        try {
            let responsePlayerCss = document.createElement('link');
            responsePlayerCss.href = `https://video.momagic.com/assets/responsive-css/responsive_${videoWidth}_${videoHeight}.css`;
            responsePlayerCss.rel = 'stylesheet';
            document.head.appendChild(responsePlayerCss);
        } catch (e) { console.log(e); }
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; SameSite=None; secure;" + expires + ";path=/";
    }

    function setupCookie() {
        try {
            let cookieValue = getCookie('tr-interactive');
            if (!cookieValue) {
                uuidVal = uuid();
                setCookie('tr-interactive', uuidVal, 365);
            } else {
                uuidVal = cookieValue;
            }
        } catch (e) { console.log(e); }
    }

    useEffect(() => {
        try {
            if (interactionData.interactions) {
                Array.from(document.querySelectorAll("[data-tr]")).map((element, index) => {
                    const interactionType = element.getAttribute("data-tr");
                    const interactionId = action.object.id;
                    const promptType = action.object.mediaType;
                    const interactionObj = interactionData.interactions[`${interactionId}_${promptType}_${interactionType}`];
                    element.addEventListener("click", (event) => {
                        event.stopPropagation();
                        interactionObj.interaction?.map((url, index) => {
                            let imgIrack = document.createElement('img');
                            let updatedUrl = url.replace('&tevent', '_wt_0&tevent');
                            updatedUrl += '&t=' + new Date().getTime();
                            imgIrack.src = updatedUrl;
                        });
                    });
                });
            }
        } catch (e) { console.log(e) }
    }, [action]);

    useEffect(() => {
        // RUN BEFORE LOAD JSX
        setupCookie();
        let player = videojs('my-video');
        setPlayer(player);
        getVideoById(player, video_id);
        includeSlotResponsiveCss();
    }, []);

    useEffect(() => {
        // Array.from(document.querySelectorAll('.draggable_child_div_cta button')).map(
        //     (e, i) => {
        //         let fontSize = Math.abs((25 * calcVideoWidthPercent) / 100);
        //         e.style.fontSize = fontSize > 15 ? 15 + 'px' : fontSize + 'px';
        //         // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
        //     }
        // )
        Array.from(document.querySelectorAll('.draggable_child_div_cta p')).map(
            (e, i) => {
                let fontSize = Math.abs((25 * calcVideoWidthPercent) / 100);
                e.style.fontSize = fontSize > 15 ? 15 + 'px' : fontSize + 'px';
                // e.style.margin = 0;
                // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
            }
        )
    }, [videoStarted]);

    // useEffect(() => {
    //     Array.from(document.querySelectorAll('.first_video_btns span')).map(
    //         (e, i) => {
    //             let fontSize = Math.abs((20 * calcVideoWidthPercent) / 100);
    //             e.style.fontSize = fontSize > 13 ? 13 + 'px' : fontSize + 'px';
    //             // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
    //         }
    //     )
    // }, [showBranching]);

    useEffect(() => {
        if (playerVideoInfo) {
            let eventList = [];
            eventList.map((event) => {
                player.off(event);
            });

            player.src({
                src: playerVideoInfo.videoPath.startsWith("http") ? playerVideoInfo.videoPath + `?${Math.random()}` : "https://storage.googleapis.com/interactive-truevdo/main_video_dir/" + playerVideoInfo.videoPath + `?${Math.random()}`,
                type: playerVideoInfo.videoContentType
            });

            player.playsinline(true);

            if (videoIdsArr.length) {
                if (videoIdsArr.indexOf(playerVideoInfo.id) < 0) {
                    setVideoIdsArr([...videoIdsArr, playerVideoInfo.id])
                }
            } else {
                setVideoIdsArr([playerVideoInfo.id]);
            }

            manageEvents(player, playerVideoInfo.contentMedia, playerVideoInfo.id);

            if (playerVideoInfo && (playerVideoInfo.interactionEventData || Object.keys(interactionData).length)) {
                processInteractionData();
            }
            if (playerVideoInfo && Object.keys(interactionData).length) {
                manageVideoInteraction('resp', playerVideoInfo.id);
            }
            removeSeekbarIcons();

            setTimeout(() => {
                // showTemplateHeader();
            }, 1000);

            // Array.from(document.querySelectorAll('.formQuizRender h2')).map(
            //     (e, i) => {
            //         // 
            //         let fontSize = Math.abs((37 * calcVideoWidthPercent) / 100);
            //         e.style.fontSize = fontSize > 22 ? 22 + 'px' : fontSize + 'px';
            //     }
            // )
            // Array.from(document.querySelectorAll('.formQuizRender h3')).map(
            //     (e, i) => {
            //         // 
            //         let fontSize = Math.abs((30 * calcVideoWidthPercent) / 100);
            //         e.style.fontSize = fontSize > 17 ? 17 + 'px' : fontSize + 'px';

            //         // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
            //     }
            // )
            // Array.from(document.querySelectorAll('.formQuizRender button')).map(
            //     (e, i) => {
            //         // 
            //         let fontSize = Math.abs((30 * calcVideoWidthPercent) / 100);
            //         e.style.fontSize = fontSize > 17 ? 17 + 'px' : fontSize + 'px';

            //         // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
            //     }
            // )
            // Array.from(document.querySelectorAll('.formQuizRender input[type="text"]')).map(
            //     (e, i) => {
            //         // 
            //         let height = Math.abs((65 * calcVideoWidthPercent) / 100);
            //         e.style.height = height > 40 ? 40 + 'px' : height + 'px';
            //         // e.style.height = Math.abs(( 50 * calcVideoWidthPercent)/100) + 'px';
            //     }
            // )
            // Array.from(document.querySelectorAll('.formQuizRender select')).map(
            //     (e, i) => {
            //         // 
            //         let height = Math.abs((65 * calcVideoWidthPercent) / 100);
            //         e.style.height = height > 40 ? 40 + 'px' : height + 'px';
            //         // e.style.height = Math.abs(( 50 * calcVideoWidthPercent)/100) + 'px';
            //     }
            // )
            Array.from(document.querySelectorAll('.text-button p')).map(
                (e, i) => {
                    // 
                    e.style.margin = 0;
                    // e.style.height = Math.abs(( 50 * calcVideoWidthPercent)/100) + 'px';
                }
            )
            // if (showBranching) {
            //     Array.from(document.querySelectorAll('.first_video_btns span')).map(
            //         (e, i) => {
            //             let fontSize = Math.abs((20 * calcVideoWidthPercent) / 100);
            //             e.style.fontSize = fontSize > 14 ? 14 + 'px' : fontSize + 'px';
            //             // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
            //         }
            //     )
            // }

        }
    }, [playerVideoInfo]);

    // useEffect(() =>{
    //     if(quizFormScore){
    //         document.getElementsByClassName('draggable_parent_div')[0].classList.add('parentOverlay');
    //     }else{
    //         document.getElementsByClassName('draggable_parent_div')[0].classList.remove('parentOverlay');
    //     }
    // }, [quizFormScore]);

    window.addEventListener("scroll", () => {
        const scrolled = window.scrollY;
        const d = document.querySelector("video");
    });

    console.log(playerVideoInfo);

    return (
        <Fragment>
            <div className="panel_body_fl">
                <div className="panel_video">
                    <div className="draggable_parent_div">
                        <div id='videoDiv'>
                            <video id="my-video" className="video-js" preload="auto" width={videoWidth} height={videoHeight} muted controls data-setup={{ "poster": poster }} playsinline>
                                {/* <source id="source" src={"https://media.w3.org/2010/05/sintel/trailer.mp4"} type="video/mp4" /> */}
                            </video>
                        </div>
                        {/* Quiz score */}
                        {
                            quizFormScore ?
                                <div className='quizFormStyle'>
                                    <div class="alert alert-warning alert-dismissible fade show" role="alert" dangerouslySetInnerHTML={{ __html: quizFormScore }}>
                                    </div>
                                    <span aria-hidden="true" onClick={() => {
                                        setquizFormScore('');
                                    }}>&times;</span>
                                </div>
                                : null
                        }

                        {showMsgEnable === true ?
                            <div className='player_popup'>
                                {/* <div className='player_popup_child' dangerouslySetInnerHTML={{ __html: action.actionContent }} style={{ width: newModalWidth, maxHeight: newModalHeight }}></div> */}
                                <div className='player_popup_child' dangerouslySetInnerHTML={{ __html: action.actionContent }} ></div>
                                {/* <center> */}
                                <button className='btn btn-danger modal_cross' onClick={() => {
                                    setShowMsgEnable(false);
                                    setBranchingMappingShowHide(true);
                                    setLandingPageObjVisible(true);
                                    player.play();
                                    // if (fixedTemplateContent) {
                                    //     setFixedTemplateVisible(true);
                                    // }
                                    let element = document.getElementById('template_div_id');
                                    if (element) {
                                        element.classList.remove('hideAutoFixedTemp');

                                    }
                                    // player.controls(true);
                                    if (playerVideoInfo.videoControls !== 0) {
                                        document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex';
                                    }
                                    document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
                                }}></button>
                                {/* </center> */}
                            </div>
                            : null
                        }
                        {
                            videoStarted && landingPageObj.ctaStatus == 1 && landingPageObjVisible ?
                                <div className={landingPageObj.landingCtaType ? 'draggable_child_div_cta react-draggable' : 'draggable_child_div_cta react-draggable cta_button'}
                                    id="cta_settings"
                                    onClick={(event) => triggerLandingURL(event)}
                                    dangerouslySetInnerHTML={{ __html: landingPageObj.landingPageContent }}
                                    style={{
                                        // height: landingPageObj.landingPageDaggableProp.height,
                                        // width: landingPageObj.landingPageDaggableProp.width,
                                        transform: 'translate(' + landingPageObj.landingPageDaggableProp.defaultPosition.x + 'px, ' + landingPageObj.landingPageDaggableProp.defaultPosition.y + 'px)',
                                        display: 'block'
                                    }}>
                                </div>
                                // <button className='bg-primary edit_btn_danger btnBlue' onClick={(event) => triggerLandingURL(event)} style={{ left: "0", right: "auto" }}>
                                //     Trigger Landing URL
                                // </button>
                                : null
                        }
                        {
                            returnBtnVisible ?
                                <div>
                                    <button className='bg-primary edit_btn_danger btnBlue me-5' onClick={(event) => returnToMainVideo(event)} style={{ top: "43%" }}>
                                        Return <img src={"/" + helper.baseURL() + '/assets/returnIcon.png'} />
                                    </button>
                                    {/* <button className='bg-primary edit_btn_danger btnBlue me-5' onClick={(event) => returnToPrevVideo(event)} style={{ marginTop: "15px" }}>
                                        Return
                                    </button> */}
                                </div>
                                : null
                        }
                        {/* {
                            branchingMapping ?
                                <div className="branching_edit_btns" style={{ display: "flex" }}>
                                    <button className='bg-primary'>
                                        <span onClick={() => setBranchingMappingShowHide(!branchingMappingShowHide)} className="btn-danger">
                                            {branchingMappingShowHide === true ? "Hide Branching" : "Show Branching"}
                                        </span>
                                    </button>

                                </div>
                                : null
                        } */}

                        {/* {
                            showBranching ?
                                showMsgEnable || branchingMappingShowHide !== true ? null : playerVideoInfo.branchMappingList ?
                                    <div className='player_popup_branching' style={{ width: 'max-content' }}>
                                        <div className="branching_icons">
                                            {
                                                playerVideoInfo.branchMappingList?.map((object, index) => {
                                                    return (
                                                        <React.Fragment key={object.id}>
                                                            <div style={{ width: calculatedBranchingWidth, minWidth: calculatedBranchingWidth, height: calculatedBranchingHeight }} className="first_video_btns" onClick={() => navigateToBranch(object)}>
                                                                <img src={object.mappingIcon.startsWith("http") ? object.mappingIcon : "https://storage.googleapis.com/interactive-truevdo/add_branching_dir/" + object.mappingIcon} style={{ height: calculatedBranchingImgsHeight, minHeight: calculatedBranchingImgsHeight }} />
                                                                
                                                                <span>{object.mappingLabel}</span>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    : console.log("Branching Mapping Not Found...")
                                : null
                        } */}

                        {
                            showBranching ?
                                showMsgEnable || branchingMappingShowHide !== true ? null : playerVideoInfo.branchMappingList ?

                                    playerVideoInfo.branchMappingList.length > 0 ?
                                        <div className='player_popup_branching' style={{
                                            transform: `translate(${JSON.parse(playerVideoInfo.branchMappingList[0].draggableProps).defaultPosition.x}px,${JSON.parse(playerVideoInfo.branchMappingList[0].draggableProps).defaultPosition.y}px)`
                                        }}>
                                            <div className={`branching_icons ${Number(playerVideoInfo.branchMappingList[0].branchingTheme) === 1 ? "branchingVertical" : ""}`} >
                                                {
                                                    playerVideoInfo.branchMappingList.map((object, index) => {
                                                        return (
                                                            <React.Fragment key={object.id}>
                                                                <div className={object.mappingType === 1 ? "first_video_btns branchingTemplate" : "first_video_btns"} onClick={() => navigateToBranch(object)}>
                                                                    <img src={object.mappingIcon} />
                                                                    <span>{object.mappingLabel}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : null
                                    : console.log("Branching Mapping Not Found...")
                                : null
                        }



                        {
                            // Template Show when Branch click
                            templateVisible ?
                                <div>
                                    <button id="btn_return_branch_template" onClick={(event) => { manageTemplateDivVisible(false); setTemplateVisible(false); player.play(); document.getElementsByClassName('draggable_parent_div')[0].classList.remove('parentOverlay'); }} className='bg-primary edit_btn_danger btnBlue me-5' style={{ marginTop: "15px" }}>Return <img src={"/" + helper.baseURL() + '/assets/returnIcon.png'} /></button>
                                    <div className="template_div" onClick={(event) => { manageBranchingTemplateInteraction(event) }} style={{ background: "" }} dangerouslySetInnerHTML={{ __html: templateContent }} id="BranchingTemplateCont"></div>
                                </div>
                                : null
                        }

                        {
                            fixedTemplateVisible ?
                                <div>
                                    {
                                        !returnBtnVisible ?
                                            <button id="btn_return_fixed" onClick={() => { closeFixedTemplate(); player.play() }} className='bg-primary edit_btn_danger btnBlue me-5' style={{ display: "none" }}>Return <img src={"/" + helper.baseURL() + '/assets/returnIcon.png'} /></button>
                                            : null
                                    }
                                    <div className="template_div" id="template_div_id" onClick={(event) => { player.pause(); manageTemplateInteraction(event) }} dangerouslySetInnerHTML={{ __html: fixedTemplateContent }} ></div>
                                </div>
                                : null
                        }

                        <div>
                            {
                                playerVideoInfo.contentMedia?.map((object, index) => {
                                    const draggableProps = object.draggableProps === null ? "" : JSON.parse(object.draggableProps);
                                    return (
                                        <Draggable key={object.mediaType + object.id} handle="section" onDrag={handleDrag} grid={draggableProps.grid}
                                            cancel={draggableProps.cancel ? draggableProps.cancel : 'div'} defaultPosition={object.mediaType !== "Form" ? draggableProps.defaultPosition : {}}
                                            bounds="video" {...dragHandlers}>
                                            {/* {(object.mediaType === "Form" && object.formType === 1) ?
                                                    <div id={'quizCross_' + index} style={{ display: 'none' }} className='quizCross' onClick={() => closeForm(index)}> </div>
                                                    : null
                                                } */}
                                            <div id={`div_${object.mediaType}_${object.id}`} onClick={() => manageInteraction(object.id, object.mediaType, 'CLICK')} style={object.mediaType === 'Form' ? {} : object.mediaType === 'TXT' && object.useAsBtn !== 1 ? { width: draggableProps.width + 'px', height: draggableProps.height + 'px', maxWidth: videoWidth + 'px', maxHeight: videoHeight + 'px', overflowY: 'auto', overflowX: 'hidden', background: '#fff' } : { width: object.imageAreaWidth }} className={object.mediaType !== "Form" ? "draggable_child_div" : "draggable_child_div customForm formZindex "}>
                                                {/* <div id={`div_${object.mediaType}_${object.id}`} onClick={() => manageInteraction(object.id, object.mediaType, 'CLICK')} style={object.mediaType === 'Form' ? { width: draggableProps.width + 'px', height: draggableProps.height + 'px', maxWidth: draggableProps.width + 'px' } : object.mediaType === 'TXT' ? {width: draggableProps.width + 'px', height: draggableProps.height + 'px' , maxWidth: videoWidth + 'px' , maxHeight : videoHeight + 'px' , overflowY:'auto' , overflowX:'hidden'}:{ width: object.imageAreaWidth }} className={object.mediaType !== "Form" ? "draggable_child_div" : "draggable_child_div customForm formZindex "}> */}

                                                <section id={`section_${object.mediaType}_${object.id}`}>
                                                    {object.mediaType === "image" ?
                                                        <div className="image_div" onClick={(event) => clickAction(object, index, event)} >
                                                            <div id={`floating_div_${object.mediaType}_${object.id}`} style={{ width: object.imageAreaWidth, background: "", position: "absolute" }}></div>
                                                            <img id={`image${(object.id)}`} className={`player_dyn_img ${object.isBranching === 1 ? "img_branching" : ""}`} src={object.imagePath} alt='logo-test' />
                                                        </div>
                                                        : null
                                                    }
                                                    {object.mediaType === "hotspot" ?
                                                        <span onClick={(event) => clickAction(object, index, event)} style={{ justifyContent: "center", display: "flex", alignItems: "center", cursor: isEditMode ? "all-scroll" : "pointer" }}>
                                                            <div className="shopping_02_cart_icon" style={{ margin: "0px" }}>
                                                                <img id={`icon${object.id}`} src={'.' + object.hotspotIconPath} />
                                                            </div>
                                                        </span>
                                                        : null
                                                    }
                                                    {object.mediaType === "TXT" ?
                                                        <span onClick={(event) => clickAction(object, index, event)} style={{ justifyContent: "center", alignItems: "center" }}>
                                                            <div id={`text${object.id}`} className={object.useAsBtn === 1 ? "text-button" : "text-container textPara"} dangerouslySetInnerHTML={{ __html: object.textContent }}>
                                                                {/* content inserted dynamically */}
                                                            </div>
                                                        </span>
                                                        : null
                                                    }

                                                    {object.mediaType === "Form" && object.formType === 1 ?
                                                        // <div style={{width: draggableProps.width + 'px', height: draggableProps.height + 'px'}} id="newFormOverflow">
                                                        <div style={{ height: 'auto' }} className="newFormOverflow formQzBg formQzCom">
                                                            <form id={`form_${(object.id)}`} className='container formQuizRender' onClick={(event) => { event.stopPropagation() }} onSubmit={(event) => {
                                                                handleFormSubmit(event, object, index)
                                                            }}>
                                                                <div id={'quizCross_' + index} className='quizCross' onClick={() => closeForm(index)}> </div>
                                                                <h2>{object.formTitle}</h2>
                                                                {
                                                                    object.fields.map((field, fieldIndex) => {
                                                                        return (
                                                                            <div className='row fieldErrorMsg' key={fieldIndex} style={{ margin: '5px 0px 5px', display: 'flex', flexFlow: 'row wrap' }}>
                                                                                <span className='fieldsLabel'>{field.label}</span>
                                                                                {
                                                                                    field.type === 1 ? <input name={field.label} type="text" id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" /> : null
                                                                                }
                                                                                {/* select box start */}
                                                                                {
                                                                                    field.type === 2 ?
                                                                                        <select name={field.label} className='config_poster_inp'>
                                                                                            {
                                                                                                <option value=''>
                                                                                                    -- Please select option --
                                                                                                </option>
                                                                                            }
                                                                                            {
                                                                                                JSON.parse(field.options).map((option, optionIndex) => {
                                                                                                    return (
                                                                                                        <option key={optionIndex} value={option.value}>
                                                                                                            {option.value}
                                                                                                        </option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        : null
                                                                                }
                                                                                {/* radio start */}
                                                                                {
                                                                                    field.type === 3 ?
                                                                                        JSON.parse(field.options).map((option, optionIndex) => {
                                                                                            return (
                                                                                                <div key={optionIndex} style={{ display: 'flex', marginBottom: '5px', marginRight: '8px', alignItems: 'center' }}>
                                                                                                    <input id={`radioIndex_` + object.id + '-' + fieldIndex + '-' + optionIndex} type='radio' onChange={() => { manageRadioValueChange(index, fieldIndex, option, optionIndex) }} value={option.value} checked={radioValueArr[index + '_' + fieldIndex] == option.value ? true : false} />
                                                                                                    <span> {option.value} </span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        : null
                                                                                }
                                                                                {/* checkbox start */}
                                                                                {
                                                                                    field.type === 4 ?
                                                                                        JSON.parse(field.options).map((option, optionIndex) => {
                                                                                            return (
                                                                                                <div key={optionIndex} style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                                                                    <input type='checkbox' value={option.value} />
                                                                                                    <span>{option.value}</span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        : null
                                                                                }
                                                                                {/* text area start */}
                                                                                {
                                                                                    field.type === 5 ? <textarea id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here"></textarea> : null
                                                                                }
                                                                                {/* datepicker start */}
                                                                                {
                                                                                    field.type === 6 ? <input type='date' id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" /> : null
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <button type='submit' className="quizBtnnw" label='Submit' style={{ fontSize: object.buttonStyle.split(',')[0], color: object.buttonStyle.split(',')[1], backgroundColor: object.buttonStyle.split(',')[2] }}>{object.buttonLabel}</button>


                                                            </form>
                                                        </div>
                                                        : null
                                                    }


                                                    {/* quiz rendering */}
                                                    {object.mediaType === "Form" && object.formType === 2 ?
                                                        <div style={{}} className="newFormOverflow formQzBg formQzCom">
                                                            <form onClick={(event) => { event.stopPropagation() }} className='container quizRenderBg formQuizRender' id={`quiz_${(object.id)}`} onSubmit={(event) => { manageQuizFieldData(event, object, index, quizIndexObj[object.id]) }}>
                                                                <div className='quizCrossQuiz quizCross' onClick={() => {
                                                                    document.querySelectorAll('.mandatoryMsg')?.forEach((e) => e.remove());
                                                                    document.querySelectorAll('.fieldMandatory')?.forEach((e) => e.classList.remove('fieldMandatory'));
                                                                    setShowMsgEnable(false);
                                                                    player.play();
                                                                    let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
                                                                    document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
                                                                    renderHtmlConMedia[index].style.display = "none";
                                                                    // player.controls(true);
                                                                    setLandingPageObjVisible(true);
                                                                    setBranchingMappingShowHide(true);
                                                                    if (playerVideoInfo.videoControls !== 0) {
                                                                        document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex';
                                                                    }
                                                                    // if (fixedTemplateContent) {
                                                                    //     setFixedTemplateVisible(true);
                                                                    // }
                                                                    let element = document.getElementById('template_div_id');
                                                                    if (element) {
                                                                        element.classList.remove('hideAutoFixedTemp');
                                                                    }
                                                                }}></div>
                                                                <h2>{object.formTitle}</h2>
                                                                <h5>Question : {quizIndexObj[object.id] + 1} / {object.fields.length}</h5>
                                                                <span className='fieldsLabel'>{object.fields[quizIndexObj[object.id]].label}</span>
                                                                {/* text field */}
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 1 ?
                                                                        <input type="text" id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" />
                                                                        : null
                                                                }
                                                                {/* select box */}
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 2 ?
                                                                        <select className='config_poster_inp'>
                                                                            {
                                                                                <option value=''>
                                                                                    -- Please select option --
                                                                                </option>
                                                                            }
                                                                            {
                                                                                JSON.parse(object.fields[quizIndexObj[object.id]].options).map((quizOption, quizOptionIndex) => {
                                                                                    return (
                                                                                        <option key={quizOptionIndex} value={quizOption.value}>
                                                                                            {quizOption.value}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        : null
                                                                }
                                                                {/* radio start */}
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 3 ?
                                                                        // <RenderRadio object={object} quizIndexObj[object.id]={quizIndexObj[object.id]} />{}
                                                                        JSON.parse(object.fields[quizIndexObj[object.id]].options).map((quizOption, quizOptionIndex) => {
                                                                            return (
                                                                                <div key={quizOptionIndex} style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }} >
                                                                                    <input id={`radioIndex_` + object.id + '-' + quizIndexObj[object.id] + '-' + quizOptionIndex} type='radio' value={quizOption.value} onChange={() => { manageRadioValueChange(index, quizIndexObj[object.id], quizOption, quizOptionIndex) }} checked={radioValueArr[index + '_' + quizIndexObj[object.id]] == quizOption.value ? true : false} />
                                                                                    <span> {quizOption.value} </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        : null
                                                                }
                                                                {/* checkbox start */}
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 4 ?

                                                                        JSON.parse(object.fields[quizIndexObj[object.id]].options).map((quizOption, quizOptionIndex) => {
                                                                            return (

                                                                                <div key={quizOptionIndex} style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                                                                                    <input type='checkbox' value={quizOption.value} />
                                                                                    <span> {quizOption.value} </span>
                                                                                </div>

                                                                            )
                                                                        })

                                                                        : null
                                                                }
                                                                {/* text area start */}
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 5 ? <textarea id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here"></textarea>
                                                                        : null
                                                                }
                                                                {/* datepicker start */}
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 6 ? <input type='date' id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" /> : null
                                                                }
                                                                {
                                                                    // quizIndexObj[object.id] > 0 ?
                                                                    // <button className='quizBtnnw' onClick={() => {
                                                                    //                                                 quizIndexObj[object.id] = quizIndexObj[object.id] - 1 ;
                                                                    //                                                 setQuizIndexObj({ ...quizIndexObj });
                                                                    //                                             }
                                                                    // }>Prev</button>
                                                                    // : null
                                                                }
                                                                {
                                                                    object.fields.length - 1 != quizIndexObj[object.id] ?

                                                                        <button className='quizBtnnw' type='submit' >Continue</button>
                                                                        : <button className='quizBtnnw' type='submit' style={{ fontSize: object.buttonStyle.split(',')[0], color: object.buttonStyle.split(',')[1], backgroundColor: object.buttonStyle.split(',')[2] }}>{object.buttonLabel}</button>
                                                                }
                                                                {/* <div className='quizCross'></div> */}

                                                                {/* onClick={(event) => {manageQuizFieldData(event, object, index, quizFieldIndex)}} */}
                                                            </form>
                                                        </div>
                                                        : null
                                                    }
                                                    {String(object.isLabelVisible) === "1" ? <div id={`label${(object.id)}`} className={`${object.isBranching === 1 ? "player_dyn_img_label_branching" : "player_dyn_img_label"}`}>{object.label}</div> : null}

                                                </section>
                                            </div>
                                        </Draggable>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PlayerRender;