// true => call server api , false => call local api
let isServerOrLocal = true;
let SERVER;

class ApiConfig {  
    getServer() {
        if (isServerOrLocal) {
            SERVER = "https://videoapi.momagic.com/api"
            // SERVER = "https://videoapi.truereach.co.in" // old api access
        } else {
            SERVER = "http://localhost:8080";
        }
        return SERVER;
    }
}

export default new ApiConfig();