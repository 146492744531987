import React from 'react';
import { BrowserRouter as Router, Route, useRoutes, Routes } from "react-router-dom";

// layout file's
import PlayerRender from './components/player-render/playerRender';
import helper from './Common/helper/helper';

function AppRoutes() {
    const baseURL = '/' + helper.baseURL();
    return (
        <Router basename={baseURL}>
            <Routes>
                <Route path="/:videGuid" element={<PlayerRender />} />
            </Routes>
        </Router>
    );
}

export default AppRoutes;
