import React from "react";
import AppRoutes from "./AppRoutes";
import PlayerRender from "./components/player-render/playerRender";

// Extrnal css for reactstrap or react-bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <AppRoutes/>
  );
}

export default App;
