import http from "./http.common";

class ServicesAPI {
  GET(ApiName) {
    return http.get(ApiName);
  }

  GET_BY_ID(ApiName, id) {
    return http.get(`${ApiName}/${id}`);
  }

  POST(ApiName, data) {
    return http.post(ApiName, data);
  }

  PUT(ApiName, id, data) {
    return http.put(`${ApiName}/${id}`, data);
  }

  PUT_WITHOUT_ID(ApiName, data) {
    return http.put(`${ApiName}`, data);
  }

  DELETE(ApiName) {
    return http.delete(`${ApiName}`);
  }

  DELETE_BY_ID(ApiName, id) {
    return http.delete(`${ApiName}/${id}`);
  }

  GET_BY_ID_INI(ApiName, id, iniFlag) {
    if(iniFlag) {
      return http.get(`${ApiName}/${id}?t=true`);
    }
    return http.get(`${ApiName}/${id}`);
  }

}

export default new ServicesAPI();