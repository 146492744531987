class Helper {
    afterDecimal = (numaricValue) => {
        const value = String(numaricValue).split(".")[1];
        return value;
    };

    beforeDecimal = (numaricValue) => {
        const value = String(numaricValue).split(".")[0];
        return value;
    };

    getFileNameFromURL = (url) => {
        var index = url.lastIndexOf("/") + 1;
        var filename = url.substr(index);
        return filename;
    }

    getExtensionFromURL(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    baseURL() {
        return 'interactive-video';
    }

    // getSortedArrayList(arrayList, sortBy) {

    //     let sortedData = arrayList.sort(
    //         (objectA, objectB) => {
    //             parseFloat(objectA + ".sortBy") - parseFloat(objectB + ".sortBy")
    //         }
    //     );

    //     return sortedData;

    // }
}

export default new Helper();